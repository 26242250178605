<template>
  <div>
    <div style="" class="dateClass mt-3">
      <label
        v-if="from !== 'details'"
        style="display: block;"
        class="vs-input--label custom-label mb-1"
        >{{ label }}</label
      >

      <div
        class="uploadedImages text-center"
        v-if="clinicPhotosField.length > 0"
      >
        <span
          class="viewUploadedImagesSpan"
          @click="viewUploadedImages()"
          v-if="
            loadingClass === 'councilPermits' ||
              loadingClass === 'poisonsCertificate'
          "
        >
          View uploaded files
        </span>
        <span
          v-else
          class="viewUploadedImagesSpan"
          @click="viewUploadedImages()"
          >View uploaded images</span
        >
      </div>

      <div style="margin-top:8px;" v-if="from !== 'details'">
        <div style="text-align:center;position:relative;">
          <label
            :id="`${loadingClass}`"
            :class="`${loadingClass}`"
            class="vs-con-loading__container"
            style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
          >
            <section
              v-if="
                loadingClass === 'councilPermits' ||
                  loadingClass === 'poisonsCertificate'
              "
            >
              <span v-if="clinicPhotosField.length > 0">+ Add More Files</span>
              <span v-else> + Attach Files</span>
            </section>

            <section v-else>
              <span v-if="clinicPhotosField.length > 0">+ Add More Images</span>
              <span v-else> + Attach Images</span>
            </section>

            <input
              v-if="
                loadingClass === 'councilPermits' ||
                  loadingClass === 'poisonsCertificate'
              "
              type="file"
              multiple
              @change="uploadClinicMultipleImages($event)"
              :id="`id-${loadingClass}`"
              :class="`class-${loadingClass}`"
              :name="`name-${loadingClass}`"
              hidden
            />
            <input
              v-else
              type="file"
              multiple
              accept="image/*"
              @change="uploadClinicMultipleImages($event)"
              :id="`id-${loadingClass}`"
              :class="`class-${loadingClass}`"
              :name="`name-${loadingClass}`"
              hidden
            />
          </label>
        </div>
      </div>
    </div>
    <span
      v-if="from && from === 'create'"
      style="font-size: 0.65rem !important;"
      class="text-danger"
    >
      {{ errors.first(loadingClass) }}
    </span>
    <span v-else class="text-danger text-sm">
      {{ errors.first(loadingClass) }}
    </span>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-popup
        class="canShowPopup"
        fullscreen
        :title="popupTitle"
        :active.sync="popupActivo"
      >
        <ViewEditClinicUploadedImages
          :images="clinicPhotosField"
          :loadingClass="loadingClass"
          :from="from"
          @removeImage="removeImage"
        />
      </vs-popup>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "../../../main";
import ViewEditClinicUploadedImages from "./ViewEditClinicUploadedImages";

export default {
  name: "ClinicPhotos",
  components: { ViewEditClinicUploadedImages },
  props: {
    clinicPhotosField: {
      type: Array
    },
    from: {
      type: String,
      default: "register"
    },
    label: {
      type: String
    },
    loadingClass: {
      type: String
    },
    popupTitle: {
      type: String
    }
  },
  inject: ["$validator"],
  data() {
    return {
      popupActivo: false
    };
  },
  methods: {
    ...mapActions("clinic", ["uploadClinicImages", "removeClinicImages"]),
    uploadClinicMultipleImages(event) {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${this.loadingClass}`,
        scale: 0.45
      });

      let config = {
        header: {
          "Content-Type": "image/png"
        }
      };

      let data = new FormData();
      for (let i = 0; i < event.target.files.length; i++)
        data.append("clinicImages", event.target.files[i]);

      const payload = {
        key: this.loadingClass,
        data,
        config
      };

      let self = this;

      this.uploadClinicImages(payload)
        .then(res => {
          if (res.data.length === 1) {
            self.clinicPhotosField.push({
              photoUrl: res.data[0].Location
            });
          } else {
            res.data.forEach(datum => {
              self.clinicPhotosField.push({
                photoUrl: datum.Location
              });
            });
          }

          self.$vs.loading.close(`.${self.loadingClass} > .con-vs-loading`);

          document.getElementById(this.loadingClass).style.borderColor =
            "#cccccc";

          self.errors.remove(this.loadingClass);
        })
        .catch(err => {
          self.$vs.loading.close(`.${self.loadingClass} > .con-vs-loading`);

          document.getElementById(this.loadingClass).style.borderColor =
            "#ea5455";

          // let errMessage = err.data.message;

          if (err && err.status === 415) {
            self.$vs.notify({
              title: "Upload Failed",
              text: err.data.message
                ? err.data.message
                : "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          } else {
            self.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }

          // self.errors.add({ field: this.loadingClass, msg: errMessage });
        });
    },
    viewUploadedImages() {
      this.popupActivo = true;
    },
    removeImage(imageUrl, selector) {
      this.$emit("removeImage", imageUrl, selector, this.loadingClass);
    }
  },
  created() {
    EventBus.$on("closePopup", () => {
      this.popupActivo = false;
      var items = document.getElementsByClassName("canShowPopup");
      if (items.length > 0) {
        for (var i = 0; i < items.length; i++) {
          items[i].style.display = "none";
        }
      }
    });
  }
};
</script>

<style scoped>
.viewUploadedImagesSpan {
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  color: darkblue;
}
</style>
