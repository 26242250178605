<template>
  <section>
    <section v-if="from === 'create'">
      <div vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <div class="flex flex-wrap">
          <div class="flex w-full lg:w-1/2">
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('mondaysOpeningHours') ? true : false"
                :success="
                  !errors.first('mondaysOpeningHours') &&
                  weekDaysData.mondays != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="validate ? 'required' : ``"
                data-vv-as="mondays opening hours"
                label="Mondays Opening Hours"
                name="mondaysOpeningHours"
                v-model="weekDaysData.mondays"
                class="w-full"
              />

              <span class="text-danger text-sm error-text">{{
                errors.first("mondaysOpeningHours")
              }}</span>
            </div>
          </div>
          <div class="flex w-full lg:w-1/2">
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('tuesdaysOpeningHours') ? true : false"
                :success="
                  !errors.first('tuesdaysOpeningHours') &&
                  weekDaysData.tuesdays != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="validate ? 'required' : ``"
                data-vv-as="tuesdays opening hours"
                label="Tuesdays Opening Hours"
                name="tuesdaysOpeningHours"
                v-model="weekDaysData.tuesdays"
                class="w-full"
              />
              <span class="text-danger text-sm error-text">{{
                errors.first("tuesdaysOpeningHours")
              }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="flex w-full lg:w-1/2">
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('wednesdaysOpeningHours') ? true : false"
                :success="
                  !errors.first('wednesdaysOpeningHours') &&
                  weekDaysData.wednesdays != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="validate ? 'required' : ``"
                data-vv-as="wednesdays opening hours"
                label="Wednesdays Opening Hours"
                name="wednesdaysOpeningHours"
                v-model="weekDaysData.wednesdays"
                class="w-full"
              />
              <span class="text-danger text-sm error-text">{{
                errors.first("wednesdaysOpeningHours")
              }}</span>
            </div>
          </div>
          <div class="flex w-full lg:w-1/2">
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('thursdaysOpeningHours') ? true : false"
                :success="
                  !errors.first('thursdaysOpeningHours') &&
                  weekDaysData.thursdays != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="validate ? 'required' : ``"
                data-vv-as="thursdays opening hours"
                label="Thursdays Opening Hours"
                name="thursdaysOpeningHours"
                v-model="weekDaysData.thursdays"
                class="w-full"
              />
              <span class="text-danger text-sm error-text">{{
                errors.first("thursdaysOpeningHours")
              }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="flex w-full lg:w-1/2">
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('fridaysOpeningHours') ? true : false"
                :success="
                  !errors.first('fridaysOpeningHours') &&
                  weekDaysData.fridays != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="validate ? 'required' : ``"
                data-vv-as="fridays opening hours"
                label="Fridays Opening Hours"
                name="fridaysOpeningHours"
                v-model="weekDaysData.fridays"
                class="w-full"
              />
              <span class="text-danger text-sm error-text">{{
                errors.first("fridaysOpeningHours")
              }}</span>
            </div>
          </div>
          <div class="flex w-full lg:w-1/2">
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('saturdaysOpeningHours') ? true : false"
                :success="
                  !errors.first('saturdaysOpeningHours') &&
                  weekDaysData.saturdays != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="validate ? 'required' : ``"
                data-vv-as="saturdays opening hours"
                label="Saturdays Opening Hours"
                name="saturdaysOpeningHours"
                v-model="weekDaysData.saturdays"
                class="w-full"
              />
              <span class="text-danger text-sm error-text">{{
                errors.first("saturdaysOpeningHours")
              }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="flex w-full lg:w-1/2">
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('sundaysOpeningHours') ? true : false"
                :success="
                  !errors.first('sundaysOpeningHours') &&
                  weekDaysData.sundays != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="validate ? 'required' : ``"
                data-vv-as="sundays opening hours"
                label="Sundays Opening Hours"
                name="sundaysOpeningHours"
                v-model="weekDaysData.sundays"
                class="w-full"
              />
              <span class="text-danger text-sm error-text">{{
                errors.first("sundaysOpeningHours")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-else>
      <vs-input
        :danger="errors.first('mondaysOpeningHours') ? true : false"
        :success="
          !errors.first('mondaysOpeningHours') && weekDaysData.mondays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="validate ? 'required' : ``"
        data-vv-validate-on="blur"
        data-vv-as="mondays opening hours"
        label-placeholder="Mondays Opening Hours"
        name="mondaysOpeningHours"
        placeholder="Mondays Opening Hours"
        v-model="weekDaysData.mondays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("mondaysOpeningHours")
      }}</span>

      <vs-input
        :danger="errors.first('tuesdaysOpeningHours') ? true : false"
        :success="
          !errors.first('tuesdaysOpeningHours') && weekDaysData.tuesdays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="validate ? 'required' : ``"
        data-vv-validate-on="blur"
        data-vv-as="tuesdays opening hours"
        label-placeholder="Tuesdays Opening Hours"
        name="tuesdaysOpeningHours"
        placeholder="Tuesdays Opening Hours"
        v-model="weekDaysData.tuesdays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("tuesdaysOpeningHours")
      }}</span>

      <vs-input
        :danger="errors.first('wednesdaysOpeningHours') ? true : false"
        :success="
          !errors.first('wednesdaysOpeningHours') &&
          weekDaysData.wednesdays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="validate ? 'required' : ``"
        data-vv-validate-on="blur"
        data-vv-as="wednesdays opening hours"
        label-placeholder="Wednesdays Opening Hours"
        name="wednesdaysOpeningHours"
        placeholder="Wednesdays Opening Hours"
        v-model="weekDaysData.wednesdays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("wednesdaysOpeningHours")
      }}</span>

      <vs-input
        :danger="errors.first('thursdaysOpeningHours') ? true : false"
        :success="
          !errors.first('thursdaysOpeningHours') && weekDaysData.thursdays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="validate ? 'required' : ``"
        data-vv-validate-on="blur"
        data-vv-as="thursdays opening hours"
        label-placeholder="Thursdays Opening Hours"
        name="thursdaysOpeningHours"
        placeholder="Thursdays Opening Hours"
        v-model="weekDaysData.thursdays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("thursdaysOpeningHours")
      }}</span>

      <vs-input
        :danger="errors.first('fridaysOpeningHours') ? true : false"
        :success="
          !errors.first('fridaysOpeningHours') && weekDaysData.fridays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="validate ? 'required' : ``"
        data-vv-validate-on="blur"
        data-vv-as="fridays opening hours"
        label-placeholder="Fridays Opening Hours"
        name="fridaysOpeningHours"
        placeholder="Fridays Opening Hours"
        v-model="weekDaysData.fridays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("fridaysOpeningHours")
      }}</span>

      <vs-input
        :danger="errors.first('saturdaysOpeningHours') ? true : false"
        :success="
          !errors.first('saturdaysOpeningHours') && weekDaysData.saturdays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="validate ? 'required' : ``"
        data-vv-validate-on="blur"
        data-vv-as="saturdays opening hours"
        label-placeholder="Saturdays Opening Hours"
        name="saturdaysOpeningHours"
        placeholder="Saturdays Opening Hours"
        v-model="weekDaysData.saturdays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("saturdaysOpeningHours")
      }}</span>

      <vs-input
        :danger="errors.first('sundaysOpeningHours') ? true : false"
        :success="
          !errors.first('sundaysOpeningHours') && weekDaysData.sundays != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="validate ? 'required' : ``"
        data-vv-validate-on="blur"
        data-vv-as="sundays opening hours"
        label-placeholder="Sundays Opening Hours"
        name="sundaysOpeningHours"
        placeholder="Sundays Opening Hours"
        v-model="weekDaysData.sundays"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("sundaysOpeningHours")
      }}</span>
    </section>
  </section>
</template>

<script>
import { Validator } from "vee-validate";

const dict = {
  custom: {
    clinicName: {
      required: "Please enter clinic name",
    },
    address: {
      required: "Please enter address",
    },
    bestContactNumber: {
      required: "Please enter your best contact number",
    },
    orderRecipients: {
      required: "Please enter your best contact number",
    },
    mondaysOpeningHours: {
      required: "Please enter order recipients",
      email:
        "The monthly treatment invoice email(s) field must be a valid email",
    },
  },
};

Validator.localize("en", dict);

export default {
  name: "ClinicOpeningHours",
  props: ["weekDaysData", "from", "validate"],
  inject: ["$validator"],
  methods: {
    async test() {
      console.log("test", invalid);
    },
  },
  async created() {
    console.log("test", "testetsets");
    let isValid = await this.$validator.validate();
  },
};
</script>

<style scoped>
.error-text {
  font-size: 0.65rem !important;
  padding: 2px 4px 4px;
  display: block;
  margin-top: -6px;
}
</style>
