<template>
  <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
    <!-- <vs-row
      class="mb-2"
      vs-align="flex-start"
      vs-type="flex"
      vs-justify="left"
      vs-w="12"
    >
      <vs-col class="text-right" vs-justify="right" vs-align="right" vs-w="6">
        <div
          v-if="task === 'edit'"
          style="float: right;"
          :id="`image-${loadingClass}`"
          :class="`image-${loadingClass} vs-con-loading__container`"
        >
          <label
            style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px; text-align: center; max-width: 195px; background: #3DC9B3; color: #fff; font-weight: 600;"
          >
            + Add More Images
            <input
              type="file"
              multiple
              accept="image/*"
              @change="addMoreImage($event, `image-${loadingClass}`)"
              id=""
              hidden
            />
          </label>
        </div>
      </vs-col>
    </vs-row> -->

    <vs-col
      v-for="(item, index) in images"
      :key="index"
      vs-type="flex"
      vs-justify="left"
      vs-align="left"
      vs-w="20%"
    >
      <div
        :id="`image-${loadingClass}-${index}`"
        class="vs-con-loading__container"
      >
        <div class="mb-6 mr-1">
          <div
            v-if="determineFileType(item.photoUrl) !== 'pdf'"
            class="img-div mb-2 relative"
          >
            <a :href="item.photoUrl" target="_blank">
              <img :src="item.photoUrl" class="" />
            </a>
            <div class="image-trash absolute" v-if="from !== 'details'">
              <feather-icon
                @click="
                  removeImage(item.photoUrl, `image-${loadingClass}-${index}`)
                "
                icon="Trash2Icon"
              ></feather-icon>
            </div>
          </div>

          <div v-else class="img-div pdf-div mb-2 relative">
            <a :href="item.photoUrl" target="_blank">View File</a>
            <div class="image-trash absolute" v-if="from !== 'details'">
              <feather-icon
                @click="
                  removeImage(item.photoUrl, `image-${loadingClass}-${index}`)
                "
                icon="Trash2Icon"
              ></feather-icon>
            </div>
          </div>
        </div>
      </div>
    </vs-col>
  </vs-row>
</template>

<script>
import axios from "@/axios";

export default {
  props: ["loadingClass", "images", "from"],
  inject: ["$validator"],
  data() {
    return {};
  },
  methods: {
    removeImage(url, selector) {
      this.$emit("removeImage", url, selector, this.loadingClass);
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    }

    //   addProductImage(event, key) {
    //     this.$vs.loading({
    //       background: "#fff",
    //       color: "#626262",
    //       container: `#add-more-product-image`,
    //       text: "uploading",
    //       scale: 0.45
    //     });
    //     let self = this;
    //     let data = new FormData();
    //     for (let i = 0; i < event.target.files.length; i++)
    //       data.append("profileImage", event.target.files[i]);

    //     let config = {
    //       header: {
    //         "Content-Type": "image/png"
    //       }
    //     };
    //     axios
    //       .post("/api/v1/store/products/uploadImages?key=" + key, data, config)
    //       .then(res => {
    //         if (res.data.length > 0) {
    //           this.$emit("addImagesToProduct", res.data);
    //         }
    //         self.$vs.loading.close(`#add-more-product-image > .con-vs-loading`);
    //       })
    //       .catch(err => {
    //         self.$vs.loading.close(`#add-more-product-image > .con-vs-loading`);
    //         self.$vs.notify({
    //           title: "Upload Failed",
    //           text: "Please try again later.",
    //           iconPack: "feather",
    //           icon: "icon-alert-circle",
    //           color: "danger"
    //         });
    //       });
    //   }
  }
};
</script>

<style scoped>
.img-div {
  width: 150px;
  height: 150px;
  border: 2px solid #ddd;
  overflow: hidden;
}

.img-div img {
  max-width: 100% !important;
  height: auto !important;
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.pdf-div {
  border: 2px solid #ddd;
}

.pdf-div a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 20px;
}

.img-div img {
  width: 100%;
  height: 100%;
}

.image-trash {
  top: 0;
  cursor: pointer;
  color: #fff;
  background: darkred;
  padding: 2px 5px;
}
</style>
