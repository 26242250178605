var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dateClass mt-3"},[(_vm.from !== 'details')?_c('label',{staticClass:"vs-input--label custom-label mb-1",staticStyle:{"display":"block"}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.clinicPhotosField.length > 0)?_c('div',{staticClass:"uploadedImages text-center"},[(
          _vm.loadingClass === 'councilPermits' ||
            _vm.loadingClass === 'poisonsCertificate'
        )?_c('span',{staticClass:"viewUploadedImagesSpan",on:{"click":function($event){return _vm.viewUploadedImages()}}},[_vm._v(" View uploaded files ")]):_c('span',{staticClass:"viewUploadedImagesSpan",on:{"click":function($event){return _vm.viewUploadedImages()}}},[_vm._v("View uploaded images")])]):_vm._e(),(_vm.from !== 'details')?_c('div',{staticStyle:{"margin-top":"8px"}},[_c('div',{staticStyle:{"text-align":"center","position":"relative"}},[_c('label',{staticClass:"vs-con-loading__container",class:`${_vm.loadingClass}`,staticStyle:{"border":"1px solid #cccccc","display":"inline-block","width":"100%","border-radius":"5px","padding":"10px"},attrs:{"id":`${_vm.loadingClass}`}},[(
              _vm.loadingClass === 'councilPermits' ||
                _vm.loadingClass === 'poisonsCertificate'
            )?_c('section',[(_vm.clinicPhotosField.length > 0)?_c('span',[_vm._v("+ Add More Files")]):_c('span',[_vm._v(" + Attach Files")])]):_c('section',[(_vm.clinicPhotosField.length > 0)?_c('span',[_vm._v("+ Add More Images")]):_c('span',[_vm._v(" + Attach Images")])]),(
              _vm.loadingClass === 'councilPermits' ||
                _vm.loadingClass === 'poisonsCertificate'
            )?_c('input',{class:`class-${_vm.loadingClass}`,attrs:{"type":"file","multiple":"","id":`id-${_vm.loadingClass}`,"name":`name-${_vm.loadingClass}`,"hidden":""},on:{"change":function($event){return _vm.uploadClinicMultipleImages($event)}}}):_c('input',{class:`class-${_vm.loadingClass}`,attrs:{"type":"file","multiple":"","accept":"image/*","id":`id-${_vm.loadingClass}`,"name":`name-${_vm.loadingClass}`,"hidden":""},on:{"change":function($event){return _vm.uploadClinicMultipleImages($event)}}})])])]):_vm._e()]),(_vm.from && _vm.from === 'create')?_c('span',{staticClass:"text-danger",staticStyle:{"font-size":"0.65rem !important"}},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.loadingClass))+" ")]):_c('span',{staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.errors.first(_vm.loadingClass))+" ")]),_c('vs-row',{attrs:{"vs-align":"flex-start","vs-type":"flex","vs-justify":"left","vs-w":"12"}},[_c('vs-popup',{staticClass:"canShowPopup",attrs:{"fullscreen":"","title":_vm.popupTitle,"active":_vm.popupActivo},on:{"update:active":function($event){_vm.popupActivo=$event}}},[_c('ViewEditClinicUploadedImages',{attrs:{"images":_vm.clinicPhotosField,"loadingClass":_vm.loadingClass,"from":_vm.from},on:{"removeImage":_vm.removeImage}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }